import axios from 'axios';
import { Fragment, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export default function About() {
  const [organisersData, setOrganisersData] = useState();

  useEffect(() => {
    axios
      .get('/organisers.json')
      .then((res) => {
        setOrganisersData(res.data);
        console.log('About: organisers = ', res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const data = 'lorem <b>ipsum</b>';

  return (
    <div>
      <section
        className='max-w-md mx-auto py-6 px-4 divide-y-2 divide-fmb-medium-cornflowerblue sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:py-8 lg:px-8 text-gray-200'
        aria-labelledby='join-heading'
      >
        <div>
          <h1 className='text-2xl text-center font-extrabold tracking-wide text-fmb-white md:text-3xl lg:text-4xl'>
            FILM MEANS BUSINESS
          </h1>

          <div className='w-2/3 mx-auto opacity-90 mt-8'>
            <img src='images/events/FMB_montage_20220620v2cropped.jpg' alt='' />
          </div>

          <div className='w-2/3 mt-8 mx-auto'>
            <p className='text-left mt-4'>
              Film Means Business has, for over a decade, brought the film and
              entertainment industry together for the highest quality panel and
              networking events. We are committed to help people learn insights
              of knowledge from our panel of experts and provide a friendly
              environment to meet other talented people for possible
              collaborations.
            </p>
          </div>

          <div className='text-xl tracking-wide font-bold text-center mt-8 lg:mt-10'>
            The Organiser(s):
          </div>
        </div>
        <div className='w-2/3 mx-auto mt-4'>
          {organisersData?.organisers?.map((organiser, idx) => (
            <div
              key={uuidv4()}
              className='overflow-hidden grid grid-cols-6 mt-4 shadow-sm'
            >
              <img
                src={organiser.imageUrl}
                alt=''
                className='aspect-video z-40 w-32 object-cover px-4 pb-4 pt-2 mx-auto col-span-1'
              />
              <div className='col-start-2 col-span-5 text_link'>
                <p className='mb-2'>
                  <span className='font-bold tracking-wide'>
                    {organiser.name}
                  </span>{' '}
                  - {organiser.role}
                </p>
                <p dangerouslySetInnerHTML={{ __html: organiser.text }} />
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
