import React, { useEffect, useState } from 'react';
import useScrollBlock from '../hooks/useScrollBlock';
import BannerCarouselChild from './BannerCarouselChild';

export default function BannerCarousel({ events }) {
  console.log('BannerCarousel: events = ', events);

  return (
    <div className='relative bg-fmb-dark-gray rounded-lg pb-2'>
      <div className='carousel relative z-30 bg-fabstir-light-purple shadow-xl shadow-fabstir-light-purple/50'>
        <div
          id='view'
          className='carousel-inner relative overflow-hidden lg:w-2/3 mx-auto px-4 lg:px-0'
        >
          {events.map((event, idx) => (
            <BannerCarouselChild
              event={event}
              idx={events.length - idx - 1}
              eventsLength={events.length}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
