import { Fragment, useEffect, useState } from 'react';
import BannerCarousel from './components/BannerCarousel';
import EventView from './components/EventView';
import LandingPageBanner from './components/LandingPageBanner';

export default function LandingPage({ eventsData }) {
  if (!eventsData) return;

  const upcomingEvents = eventsData.events.filter(
    (event) => Date.parse(event.when) && Date.parse(event.when) >= Date.now()
  );
  return (
    <div className='overflow-hidden' id='view1'>
      <LandingPageBanner />

      {upcomingEvents.length > 0 && (
        <section
          className='max-w-md mx-auto py-6 px-4 divide-y-2 divide-fmb-divide-blue-gray sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:py-10 lg:px-8 text-gray-200'
          aria-labelledby='join-heading'
        >
          <h2 className='text-3xl font-extrabold text-fmb-light-gray text-left mb-6 ml-2'>
            Upcoming Events
          </h2>

          <div className='-z-30 flex h-full flex-1 flex-col overflow-hidden bg-fmb-dark-gray rounded-lg'>
            <div className='relative'>
              <div className='relative z-30 bg-fabstir-light-purple shadow-xl shadow-fabstir-light-purple/50'>
                <div className='relative overflow-hidden lg:w-2/3 mx-auto px-4'>
                  {upcomingEvents.map((event, idx) => (
                    <EventView
                      event={event}
                      idx={idx}
                      eventsLength={eventsData.events.length}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* Carousel Section */}
      <section
        className='max-w-md mx-auto py-8 px-4 divide-y-2 divide-fmb-divide-blue-gray sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:py-12 lg:px-8'
        aria-labelledby='join-heading'
      >
        <h2 className='text-3xl font-extrabold text-fmb-light-gray text-left mb-6 ml-2'>
          Past Events
        </h2>
        <div className='-z-30 overflow-hidden'>
          <BannerCarousel
            events={eventsData.events
              .filter(
                (event) =>
                  !Date.parse(event.when) || Date.parse(event.when) < Date.now()
              )
              .reverse()}
          />
        </div>
      </section>

      {/* Newsletter Section */}
      <section
        className='max-w-md mx-auto py-4 px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:py-6 lg:px-8 lg:flex lg:items-center'
        aria-labelledby='newsletter-heading'
      >
        <div className='lg:w-0 lg:flex-1'>
          <h2
            className='text-3xl font-extrabold text-fmb-light-gray sm:text-4xl'
            id='newsletter-heading'
          >
            Sign up for our newsletter
          </h2>
          <p className='mt-3 max-w-3xl text-lg text-blue-gray-500'>
            Keep up to date with Film Means Business events.
          </p>
        </div>
        <div className='mt-8 lg:mt-0 lg:ml-8' id='signup'>
          <form action='/send_email.php' className='sm:flex'>
            <label htmlFor='email-address' className='sr-only'>
              Email address
            </label>
            <input
              id='email-address'
              name='email-address'
              type='email'
              autoComplete='email'
              required
              className='w-full px-5 py-3 border bg-fmb-dark-gray border-fmb-medium-cornflowerblue shadow-sm placeholder-blue-gray-400 focus:ring-1 focus:ring-fmb-medium-blue focus:border-fmb-medium-blue sm:max-w-xs rounded-md text-fmb-light-gray'
              placeholder='Enter your email'
            />
            <div className='mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0'>
              <button
                type='submit'
                className='w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-fmb-white bg-fmb-medium-blue hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-fmb-medium-blue'
              >
                Subscribe
              </button>
            </div>
          </form>
          <p className='mt-3 text-sm text-blue-gray-500'>
            We care about the protection of your data. Read our{' '}
            <a href='#' className='font-medium underline'>
              Privacy Policy.
            </a>
          </p>
        </div>
      </section>
    </div>
  );
}
