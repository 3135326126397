import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

export default function BannerCarouselChild({
  event,
  idx,
  eventsLength,
  scrollToElement,
}) {
  console.log('BannerCarouselChild: event = ', event);
  console.log('BannerCarouselChild: idx = ', idx);

  function disableScroll() {
    // Get the current page scroll position
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const scrollLeft =
      window.pageXOffset || document.documentElement.scrollLeft;

    // if any scroll is attempted, set this to the previous value
    window.onscroll = function () {
      window.scrollTo(scrollLeft, scrollTop);
    };
  }

  function enableScroll() {
    window.onscroll = function () {};
  }

  function scrollTo() {
    const y = window.pageYOffset;
    disableScroll();
    //    disableBodyScroll(document);

    setTimeout(function () {
      const label = document.getElementById(`label-${idx + 1}`);
      label.htmlFor = `carousel-${Math.abs((idx - 1) % eventsLength) + 1}`;
      //    enableBodyScroll(document);
      enableScroll();
      window.scrollTo(0, y);

      console.log('BannerCarouselChild: scrollTo()');
      console.log('BannerCarouselChild: scrollToElement = ', scrollToElement);
      console.log('BannerCarouselChild: idx = ', idx);
    }, 0);
  }

  return (
    <>
      {/* <!--Slide 1--> */}
      <input
        className='carousel-open opacity-0'
        type='radio'
        id={`carousel-${idx + 1}`}
        name='carousel'
        aria-hidden={true}
        defaultChecked={idx === 0 ? true : false}
        hidden={true}
      />
      <div className='carousel-item absolute opacity-0 mt-10 text-fmb-light-gray h-full'>
        <div className='text-2xl font-bold text-center mb-6'>{event.title}</div>

        {event?.imageUrl && (
          <img
            src={event.imageUrl}
            alt=''
            className='w-2/3 px-4 pb-4 pt-2 mx-auto'
          />
        )}

        <div className='text-l text-center mb-5'>
          <span className='font-semibold'>when:</span> {event.when}
        </div>
        <div className='text-l text-center mb-5'>
          <span className='font-semibold'>where</span>: {event.where}
        </div>
        <div className='text-l text-center mb-5'>
          <span className='font-semibold'>itinerary:</span> {event.itinerary}
        </div>
        <div className='text-l mb-10 text-left'>{event.description}</div>

        <div className='text-xl tracking-wide font-bold text-center mb-6'>
          The Panel:
        </div>

        {event?.panelists?.length > 0 && (
          <>
            {event.panelists.map((panelist, panelistIdx) => (
              <div
                key={uuidv4()}
                className='overflow-hidden grid grid-cols-6 mb-8'
              >
                <img
                  src={panelist.imageUrl}
                  alt=''
                  className='aspect-video z-40 w-32 object-cover px-4 pb-4 pt-2 mx-auto col-span-1'
                />
                <div className='col-start-2 col-span-5'>
                  <p className='mb-2'>
                    <span className='font-bold tracking-wide'>
                      {panelist.name}
                    </span>{' '}
                    - {panelist.role}
                  </p>
                  <p>{panelist.text}</p>
                </div>
              </div>
            ))}
          </>
        )}

        {event?.panelists?.length === 0 && <span> TBA</span>}

        <div className='text-xl tracking-wide font-bold text-center mb-6'>
          Hosted by:
        </div>
        {event.hosts.map((host, hostIdx) => (
          <div key={uuidv4()} className='overflow-hidden grid grid-cols-6 mb-8'>
            <img
              src={host.imageUrl}
              alt=''
              className='aspect-video z-40 w-32 object-cover px-4 pb-4 pt-2 mx-auto col-span-1  shadow-sm'
            />
            <div className='col-start-2 col-span-5'>
              <p className='mb-2'>
                <span className='font-bold tracking-wide'>{host.name}</span> -{' '}
                {host.role}
              </p>
              <p>{host.text}</p>
            </div>
          </div>
        ))}

        <div className='mb-2 lg:mb-4'>
          Organised by{' '}
          {event.organisers.map((organiser, organiserIdx) => (
            <span key={uuidv4()} className=''>
              {organiserIdx > 0 && <span> and </span>}
              {organiser.name}
            </span>
          ))}
        </div>
      </div>

      {idx > 0 && (
        <label
          onClick={() => scrollTo()}
          id={`label-${idx + 1}`}
          for={`carousel-${Math.abs((idx - 1) % eventsLength) + 1}`}
          className={`prev control-${
            idx + 1
          } absolute inset-y-0 left-0 z-10 ml-2 hidden md:ml-10 overflow-hidden mt-1`}
        >
          <span className='flex items-center h-10 w-10 cursor-pointer rounded-full text-center text-4xl font-bold leading-tight text-fmb-light-gray hover:bg-blue-700 hover:text-fmb-white md:mr-10 justify-center'>
            ‹
          </span>
        </label>
      )}
      <label
        className={`control-${
          idx + 1
        } absolute mt-4 inset-y-0 left-1/2 -translate-x-1/2 x- z-10 hidden cursor-pointer rounded-full bg-fabstir-black/50 text-center text-l font-bold text-fmb-light-gray`}
      >
        {idx + 1}/{eventsLength}
      </label>

      {idx < eventsLength - 1 && (
        <label
          for={`carousel-${Math.abs((idx + 1) % eventsLength) + 1}`}
          // className={`next control-${
          //   idx + 1
          // } absolute mt-1 inset-y-0 right-0 z-10 mr-2 hidden h-10 w-10 cursor-pointer rounded-full bg-fabstir-black/50 text-center text-4xl font-bold leading-tight text-fmb-light-gray hover:bg-blue-700 hover:text-fmb-white md:mr-10 overflow-hidden`}
          className={`next control-${
            idx + 1
          } absolute inset-y-0 right-0 z-10 mr-2 hidden overflow-hidden md:mr-10 mt-1`}
        >
          <span className='flex items-center h-10 w-10 cursor-pointer rounded-full text-center text-4xl font-bold leading-tight text-fmb-light-gray hover:bg-blue-700 hover:text-fmb-white justify-center'>
            ›
          </span>
        </label>
      )}
    </>
  );
}
