import { Fragment } from 'react';

export default function LandingPageBanner() {
  return (
    <div className='relative bg-fmb-dark-cornflowerblue'>
      <div className='relative pt-4 max-w-md mx-auto px-4 pb-8 sm:max-w-3xl sm:px-6 md:pt-6 lg:max-w-7xl lg:px-8 w-2/3'>
        <div className='absolute max-w-3xl mx-auto px-6 flex flex-col text-center lg:px-0 z-10 top-0 sm:translate-y-1/2 left-0 sm:left-0 md:left-0 lg:left-6  xl:left-8 2xl:left-10'>
          <h1 className='text-2xl font-extrabold tracking-wide text-fmb-white md:text-3xl lg:text-4xl'>
            FILM MEANS BUSINESS
          </h1>
          <p className='mt-6 max-w-3xl text-blue-gray-200 text-xs sm:text-xs md:text-sm lg:text-md xl:text-lg 2xl:text-xl text-left px-4 line-clamp-4'>
            The place to be for film and entertainment panel and networking
            events. Is making movies a black art? How do you raise financing?
            How do you get your film sold? How to deal with tax relief systems?
            The light is shone on these and may more questions, to be answered
            by distinguished panels of guests from the film industry.
          </p>
        </div>

        {/* Hero section */}
        <div className='relative bg-gray-900'>
          {/* <div className='absolute bg-gray-900 bottom-0 left-1/2 translate-y-1/2 w-screen h-full opacity-100 -z-0'></div>
          <div className='absolute bg-gray-900 bottom-0 right-1/2 translate-y-1/2 w-screen h-full opacity-100 -z-0'></div> */}
          {/* Decorative image and overlay */}
          <div aria-hidden='true' className='overflow-hidden opacity-50'>
            <img
              src='images/events/DSC_0030.jpg'
              alt=''
              className='shadow-lg'
            />
          </div>
          <div aria-hidden='true' className=' bg-gray-900 opacity-50' />
        </div>
      </div>
    </div>
  );
}
