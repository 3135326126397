import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export default function EventView({ event, idx, eventsLength }) {
  console.log('EventView: event = ', event);

  return (
    <div className='mt-4 lg:mt-6 text-fmb-light-gray'>
      <div className='text-2xl font-bold text-center mb-6'>{event.title}</div>

      {event?.imageUrl && (
        <img
          src={event.imageUrl}
          alt=''
          className='w-2/3 px-4 pb-4 pt-2 mx-auto'
        />
      )}

      <div className='text-l text-center mb-5'>
        <span className='font-semibold'>when:</span> {event.when}
      </div>
      <div className='text-l text-center mb-5'>
        <span className='font-semibold'>where</span>: {event.where}
      </div>
      <div className='text-l text-center mb-5'>
        <span className='font-semibold'>itinerary:</span> {event.itinerary}
      </div>
      <div className='text-l mb-10 text-left'>{event.description}</div>
      <div className='text-xl tracking-wide font-bold text-center mb-6'>
        The Panel:
      </div>

      {event?.panelists?.length > 0 && (
        <>
          {event.panelists.map((panelist, panelistIdx) => (
            <div
              key={uuidv4()}
              className='overflow-hidden grid grid-cols-6 mb-8'
            >
              <img
                src={panelist.imageUrl}
                alt=''
                className='aspect-video z-40 w-32 object-cover px-4 pb-4 pt-2 mx-auto col-span-1'
              />
              <div className='col-start-2 col-span-5'>
                <p className='mb-2'>
                  <span className='font-bold tracking-wide'>
                    {panelist.name}
                  </span>{' '}
                  - {panelist.role}
                </p>
                <p>{panelist.text}</p>
              </div>
            </div>
          ))}
        </>
      )}

      {event?.panelists?.length === 0 && <span> TBA</span>}

      <div className='text-xl tracking-wide font-bold text-center mb-6'>
        Hosted by:
      </div>
      {event?.hosts?.map((host, hostIdx) => (
        <div
          key={uuidv4()}
          className='overflow-hidden grid grid-cols-6 mb-8 shadow-sm'
        >
          <img
            src={host.imageUrl}
            alt=''
            className='aspect-video z-40 w-32 object-cover px-4 pb-4 pt-2 mx-auto col-span-1'
          />
          <div className='col-start-2 col-span-5'>
            <p className='mb-2'>
              <span className='font-bold tracking-wide'>{host.name}</span> -{' '}
              {host.role}
            </p>
            <p>{host.text}</p>
          </div>
        </div>
      ))}

      <div className='mb-4 lg:mb-6'>
        Organised by{' '}
        {event?.organisers?.map((organiser, organiserIdx) => (
          <span key={uuidv4()} className=''>
            {organiserIdx > 0 && <span> and </span>}
            {organiser.name}
          </span>
        ))}
      </div>

      {event?.eventUrl && (
        <div className='mb-4 lg:mb-6 text-xl text-center'>
          To purchase tickets for the event, please visit{' '}
          <a
            className='text-fmb-medium-blue hover:text-fmb-medium-cornflowerblue'
            href={event.eventUrl}
            target='_blank'
          >
            here
          </a>
        </div>
      )}
    </div>
  );
}
