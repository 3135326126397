import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';

const navigation = [
  { name: 'About', href: '/about' },
  { name: 'Partners', href: '/partners' },
  { name: 'News', href: '/news' },
];

export default function HeaderUI() {
  return (
    <header className='relative bg-fmb-dark-cornflowerblue z-30'>
      <Popover as='div' className='relative z-10'>
        <nav
          className='relative max-w-7xl mx-auto flex items-center justify-between pt-6 pb-2 px-4 sm:px-6 lg:px-8'
          aria-label='Global'
        >
          <div className='flex items-center flex-1'>
            <div className='flex items-center justify-between w-full lg:w-auto'>
              {/* <a href='#'>
                <span className='sr-only'>Workflow</span>
                <img
                  className='h-8 w-auto sm:h-10 shadow'
                  src='images/nmx_logo.jpg'
                  alt='Non-Multiplex Cinema'
                />
              </a> */}
              <a href='/'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  class='h-6 w-6'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='rgb(229, 231, 235)'
                  stroke-width='2'
                >
                  <path
                    stroke-linecap='round'
                    stroke-linejoin='round'
                    d='M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6'
                  />
                </svg>
              </a>
              <div className='-mr-2 flex items-center lg:hidden'>
                <Popover.Button className='bg-blue-gray-900 bg-opacity-0 rounded-md p-2 inline-flex items-center justify-center text-fmb-white hover:bg-opacity-100 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white'>
                  <span className='sr-only'>Open main menu</span>
                  <MenuIcon className='h-6 w-6' aria-hidden='true' />
                </Popover.Button>
              </div>
            </div>
            <div className='hidden space-x-10 lg:flex lg:ml-10'>
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className='text-base font-medium text-fmb-white hover:text-blue-100'
                >
                  {item.name}
                </a>
              ))}
            </div>
          </div>
          <div className='hidden lg:flex lg:items-center lg:space-x-6'>
            <a
              href='#signup'
              className='py-2 px-6 bg-fmb-medium-blue border border-transparent rounded-md shadow-md text-base font-medium text-fmb-white hover:bg-blue-600'
            >
              Sign Up
            </a>
          </div>
        </nav>

        <Transition
          as={Fragment}
          enter='duration-150 ease-out'
          enterFrom='opacity-0 scale-95'
          enterTo='opacity-100 scale-100'
          leave='duration-100 ease-in'
          leaveFrom='opacity-100 scale-100'
          leaveTo='opacity-0 scale-95'
        >
          <Popover.Panel
            focus
            className='absolute top-0 inset-x-0 p-2 transition transform origin-top lg:hidden'
          >
            <div className='rounded-lg shadow-lg bg-fmb-black ring-1 ring-black ring-opacity-5 overflow-hidden'>
              <div className='px-5 pt-4 flex items-center justify-between'>
                <div>
                  <img
                    className='h-8 w-auto'
                    src='images/nmx_logo.jpg'
                    alt=''
                  />
                </div>
                <div className='-mr-2'>
                  <Popover.Button className='bg-fmb-black rounded-md p-2 inline-flex items-center justify-center text-blue-gray-400 hover:bg-blue-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-fmb-medium-blue'>
                    <span className='sr-only'>Close menu</span>
                    <XIcon className='h-6 w-6' aria-hidden='true' />
                  </Popover.Button>
                </div>
              </div>
              <div className='pt-5 pb-6'>
                <div className='px-2 space-y-1'>
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className='block px-3 py-2 rounded-md text-base font-medium text-fmb-light-gray hover:bg-blue-gray-50'
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className='mt-6 px-5'>
                  <a
                    href='#signup'
                    className='block text-center w-full py-2 px-4 border border-transparent rounded-md shadow bg-fmb-medium-blue text-fmb-white font-medium hover:bg-blue-600'
                  >
                    Sign Up
                  </a>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </header>
  );
}
